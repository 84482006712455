import { FC, useEffect, useState } from 'react';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openModal } from 'store/system/reducer';
import { getIsUserHasFullAccessSelector } from 'store/user-service/selectors';
import { DataHint, DataHintModalContent, ProviderAccordionGroup } from 'components/shared';
import {
	ADD_SERVICE_HINT_DATA,
	ADD_SERVICE_HINT_MODAL_DATA,
	FIREBASE_EVENT_ANALYTICS_BUTTON,
	FIREBASE_EVENT_ANALYTICS_PAGE,
} from 'utils/constants';
import { PROVIDER_TYPE } from 'utils/enums';
import { FirebaseAnalytics } from 'utils/services';
import { IBillReceipt, IReceiptOtherServiceResponse, ISingleReceiptPayProviderServiceRequest } from 'utils/types';
import { PaymentOtherProviderItem } from '../PaymentOtherProviderItem';

interface ReceiptPortmoneServiceListProps {
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeSelectedList: (value: ISingleReceiptPayProviderServiceRequest[]) => void;
	onChangeFee: (serviceId: string | number, amount: number, feeHostCardAmount: number) => void;
}

export const ReceiptPortmoneServiceList: FC<ReceiptPortmoneServiceListProps> = ({
	selectedServiceList,
	onChangeSelectedList,
	onChangeFee,
}) => {
	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const dispatch = useAppDispatch();

	const [serviceList, setServiceList] = useState<IReceiptOtherServiceResponse[]>([]);

	const handleOpenModal = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.QUESTION_ADD_SERVICE
		);
		dispatch(
			openModal({
				content: <DataHintModalContent {...ADD_SERVICE_HINT_MODAL_DATA} />,
			})
		);
	};

	useEffect(() => {
		if (receipt.data?.otherPaymentServices) {
			setServiceList(receipt.data?.otherPaymentServices);
		}
	}, [receipt.data?.otherPaymentServices]);

	if (!receipt.data) return null;
	return (
		<ProviderAccordionGroup marginBottom="var(--spacing-3)">
			{serviceList.map((item: IReceiptOtherServiceResponse) => (
				<PaymentOtherProviderItem
					key={item.id}
					data={item}
					onChange={onChangeSelectedList}
					selectedServiceList={selectedServiceList}
					onChangeFee={onChangeFee}
				/>
			))}
			{!receipt.data?.otherPaymentServices.length &&
				!receipt.data.serviceProviderData[PROVIDER_TYPE.PORTMONE] &&
				isHasFullAccess && <DataHint {...ADD_SERVICE_HINT_DATA} onOpenModal={handleOpenModal} />}
		</ProviderAccordionGroup>
	);
};
