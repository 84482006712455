import { Capacitor } from '@capacitor/core';
import { ReactComponent as CardIcon } from 'assets/icons/tabs/card.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/tabs/home.svg';
// import { ReactComponent as MetersIcon } from 'assets/icons/tabs/meters.svg';
import { ReactComponent as ServiceIcon } from 'assets/icons/tabs/services.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/tabs/settings.svg';
import { ROUTER_URL } from 'utils/enums';
import { INavbarItem } from 'utils/types';

export const getActiveTab = (pathname: string): ROUTER_URL => {
	let activeTab = ROUTER_URL.PROFILE;

	if (pathname.includes(ROUTER_URL.SETTINGS)) {
		activeTab = ROUTER_URL.SETTINGS;
	} else if (pathname.includes(ROUTER_URL.SERVICES_CATEGORIES)) {
		activeTab = ROUTER_URL.SERVICES_CATEGORIES;
	}
	// else if (pathname.includes(ROUTER_URL.METERS)) {
	// 	activeTab = ROUTER_URL.METERS;
	// }
	else if (pathname.includes(ROUTER_URL.RADABANK_CARD)) {
		activeTab = ROUTER_URL.RADABANK_CARD;
	}

	return activeTab;
};

export const NAVBAR_LIST: INavbarItem[] = [
	{
		label: 'Картка',
		icon: <CardIcon />,
		path: ROUTER_URL.RADABANK_CARD,
	},
	// {
	// 	label: 'Показники',
	// 	icon: <MetersIcon />,
	// 	path: ROUTER_URL.METERS,
	// },
	{
		label: 'Головна',
		icon: <HomeIcon />,
		path: ROUTER_URL.PROFILE,
	},
	{
		label: 'Послуги',
		icon: <ServiceIcon />,
		path: ROUTER_URL.SERVICES_CATEGORIES,
	},
	{
		label: 'Інше',
		icon: <SettingsIcon />,
		path: ROUTER_URL.SETTINGS,
	},
];

export const NAVBAR_LIST_WEB: INavbarItem[] = [
	{
		label: 'Головна',
		icon: <HomeIcon />,
		path: ROUTER_URL.PROFILE,
	},
	// {
	// 	label: 'Показники',
	// 	icon: <MetersIcon />,
	// 	path: ROUTER_URL.METERS,
	// },
	{
		label: 'Послуги',
		icon: <ServiceIcon />,
		path: ROUTER_URL.SERVICES_CATEGORIES,
	},
	{
		label: 'Інше',
		icon: <SettingsIcon />,
		path: ROUTER_URL.SETTINGS,
	},
];

export const getNavbarList = (isHasFullAccess: boolean) => {
	const navList = Capacitor.isNativePlatform() ? NAVBAR_LIST : NAVBAR_LIST_WEB;

	return isHasFullAccess
		? navList
		: navList.filter((item) => item.path !== ROUTER_URL.SERVICES_CATEGORIES && item.path !== ROUTER_URL.RADABANK_CARD);
};

export const formatPathname = (pathname: string) => {
	const value = pathname ? pathname?.replace(/^\//, '').split('/').join('_') : '';

	const formattedValue = value?.replace('profile', '')?.replace('_', '');

	return formattedValue || value;
};
