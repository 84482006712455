import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { Capacitor } from '@capacitor/core';
import { Box, Typography } from '@mui/material';
import { addApartment } from 'store/bill/actions';
import { getApartmentsSelector, getPossibleApartmentsLoadingSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfileSelector } from 'store/user-service/selectors';
import { AddApartmentByAddress, AddApartmentById, AddApartmentByProviderId } from 'components/Apartment';
import ApartmentsChoiceStep from 'components/Apartment/AddApartmentByAddress/ApartmentsChoiceStep';
import { CustomButton } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ADD_APARTMENT_VARIANT, ERROR_MESSAGE, ROUTER_URL, STORAGE_KEY } from 'utils/enums';
import {
	flatApartmentAccountIds,
	getAddAppartmentDto,
	getApartmentInitialValues,
	getAppartmentErrorMessage,
} from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';
import { BillService, FirebaseAnalytics, StorageService } from 'utils/services';
import { IAddApartmentAccountRequest, IAddApartmentRequest } from 'utils/types';
import styles from './index.module.scss';

interface AuthAddApartmentProps {
	variant: ADD_APARTMENT_VARIANT | null;
}

export const AuthAddApartment: FC<AuthAddApartmentProps> = ({ variant }) => {
	const dispatch = useAppDispatch();

	const history = useHistory();

	const { isLoading: isApartmentsLoading } = useAppSelector(getApartmentsSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const isReceiptLoading = useAppSelector(getPossibleApartmentsLoadingSelector);

	const [data, setData] = useState<IAddApartmentRequest>({
		userId: profile?.userId ?? '',
	});
	const [isValid, setIsValid] = useState(false);
	const [apartmentIds, setApartmentIds] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (profile) {
			setData(getApartmentInitialValues(profile.userId, variant));
			setIsValid(false);
		}
	}, [variant, profile]);

	const handleAddAddress = async (address: IAddApartmentAccountRequest) => {
		try {
			await dispatch(addApartment(address)).unwrap();
			history.push(ROUTER_URL.RECEIPT_MAIN);
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		}
	};

	const handleSubmit = async (): Promise<void> => {
		if (!isValid || !variant) return;
		try {
			setIsLoading(true);
			let buttonName = '';
			switch (variant) {
				case ADD_APARTMENT_VARIANT.ADDRESS:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_ADDRESS_SUBMIT;
					break;
				case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_HOST_ID_SUBMIT;
					break;
				case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
					buttonName = FIREBASE_EVENT_ANALYTICS_BUTTON.APARTMENT_VARIANT_BY_PROVIDER_ACCOUNT_ID_SUBMIT;
					break;

				default:
					break;
			}
			FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE, buttonName);
			await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);

			if (apartmentIds.length && data.apartmentAccountId) {
				await handleAddAddress({ userId: data.userId, apartmentAccountId: data.apartmentAccountId });
				return;
			}

			const dto = getAddAppartmentDto(variant, data);

			if (!dto) return;

			const { data: response } = await BillService.getApartmentAccountIdsList(dto);

			const responseApartmentIds = flatApartmentAccountIds(response);

			if (!responseApartmentIds.length) {
				dispatch(showToast({ message: ERROR_MESSAGE.APARTMENT_NOT_FOUND }));
				return;
			}

			if (responseApartmentIds.length > 1) {
				setApartmentIds(responseApartmentIds);
				return;
			}
			await handleAddAddress({ userId: data.userId, apartmentAccountId: responseApartmentIds[0] });
		} catch (error) {
			dispatch(showToast({ message: getAppartmentErrorMessage(error) }));
		} finally {
			setIsLoading(false);
		}
	};

	const handleClickSkip = async (): Promise<void> => {
		FirebaseAnalytics.logEvent(FIREBASE_EVENT_ANALYTICS_PAGE.AUTH.UPDATE_PROFILE, FIREBASE_EVENT_ANALYTICS_BUTTON.ADD_APARTMENT_SKIP);
		await StorageService.set(STORAGE_KEY.IS_WAS_LOGGED_IN, true);
		history.push(ROUTER_URL.PROFILE);
	};

	const handleSubmitOnEnter = useKeyPress(handleSubmit);

	const content = useMemo(() => {
		const props = {
			data,
			onChange: (value: IAddApartmentRequest) => setData(value),
			setIsValid: (value: boolean) => setIsValid(value),
			onSubmit: handleSubmitOnEnter,
		};
		switch (variant) {
			case ADD_APARTMENT_VARIANT.APARTMENT_ACCOUNT_ID:
				return <AddApartmentById {...props} />;

			case ADD_APARTMENT_VARIANT.PROVIDER_ACCOUNT_ID:
				return <AddApartmentByProviderId {...props} />;

			default:
				if (apartmentIds.length) {
					const onChangeApartmentId = (apartmentAccountId?: number) =>
						setData((prev) => ({ ...prev, apartmentAccountId }));
					return (
						<ApartmentsChoiceStep
							onChange={onChangeApartmentId}
							apartmentAccountId={data.apartmentAccountId}
							apartmentIds={apartmentIds}
						/>
					);
				}
				return <AddApartmentByAddress {...props} />;
		}
	}, [variant, data, apartmentIds, isValid]);

	return (
		<>
			{content}
			{!isReceiptLoading && (
				<Box width="100%">
					{!!apartmentIds.length && variant === ADD_APARTMENT_VARIANT.ADDRESS && (
						<Typography mb="8px" textAlign="center" variant="subtitle2" color="var(--color-neutral-300)">
							Також ви можете звернутися до нашого центру клієнтської підтримки для керування цими даними
						</Typography>
					)}
					<div className={styles.actions}>
					{Capacitor.isNativePlatform() && (
						<CustomButton fill="clear" size="large" label="Додати пізніше" onClick={handleClickSkip} />
					)}
					<CustomButton
						className="auth-button-submit"
						size="large"
						disabled={!isValid}
						label="Продовжити"
						onClick={handleSubmit}
						isLoading={isApartmentsLoading || isLoading}
						/>
					</div>
				</Box>
			)}
		</>
	);
};
