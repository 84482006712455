import { FC, HTMLProps } from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

interface ReceiptProvidersListPreview extends HTMLProps<HTMLDivElement> {
	title: string;
	chipTitle?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ReceiptProvidersListPreview: FC<ReceiptProvidersListPreview> = ({ children, title, chipTitle }) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<Typography variant="h6">{title}</Typography>
				{/* {!!chipTitle && <CustomChip label={chipTitle} color="primary" />} */}
			</div>
			{children}
		</div>
	);
};
