import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { ReactComponent as Logo } from 'assets/images/host-logo.svg';
import classNames from 'classnames';
import { getIsPumbUserAccessSelector, useAppSelector } from 'store';
// import { BarcodeScanner } from 'components/BarcodeScanner';
import { Navbar } from 'components/Navbar';
import { UserInfo } from 'components/UserInfo';
import { ROUTER_URL } from 'utils/enums';
import styles from './index.module.scss';

interface HeaderProps {
	className?: string;
	isAuth?: boolean;
}

export const Header: FC<HeaderProps> = ({ className, isAuth = false }) => {
	const { pathname } = useLocation();
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector);

	return (
		<header className={classNames(styles.header, className)}>
			<Link
				className={classNames({ [styles.header__link_disabled]: pathname === ROUTER_URL.PROFILE || isAuth })}
				to={isUserPUMB ? ROUTER_URL.PROFILE : ROUTER_URL.SERVICES_CATEGORIES}
			>
				<Logo className={styles.header__logo} />
			</Link>
			{!isAuth && (
				<>
					<Navbar className={styles.header__navbar} />
					{!Capacitor.isNativePlatform() && (
						<Link to={ROUTER_URL.SETTINGS}>
							<UserInfo />
						</Link>
					)}
				</>
			)}
		</header>
	);
};
