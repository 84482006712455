import { FC, useMemo } from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { MainLayout } from 'layouts/MainLayout';
import { AddAddressPage } from 'pages/AddAddress';
import { HomePage } from 'pages/Home';
// import { MetersPage } from 'pages/Meters';
import { NotFoundPage } from 'pages/NotFound';
import { PaymentStatusPage } from 'pages/PaymentStatus';
import { PaymentStatusServicePage } from 'pages/PaymentStatusService';
import { ReceiptPage } from 'pages/Receipt';
import { ReceiptBillsListPage } from 'pages/ReceiptBillsList';
import { ReceiptBillsReportPage } from 'pages/ReceiptBillsReport';
import { ServiceProviderPage } from 'pages/ServiceProvider';
import { ServicesPage } from 'pages/Services';
import { ServicesBillsListPage } from 'pages/ServicesBillsList';
import { ServicesBillsReportPage } from 'pages/ServicesBillsReport';
import { ServicesCategoriesPage } from 'pages/ServicesCategories';
import { SettingsPage } from 'pages/Settings';
import { SingleReceiptPage } from 'pages/SingleReceipt';
import {
	getIsPumbUserAccessSelector,
	getIsUserHasFullAccessSelector,
	getUserReducerSelector,
	useAppSelector,
} from 'store';
import { ROUTER_URL } from 'utils/enums';
// import { Rights } from 'utils/enums/rights.enum';
import { getIndexRoute } from 'utils/helpers';
import { usePermissions } from 'utils/hooks';

export const MainRouter: FC = () => {
	const isHasFullAccess = useAppSelector(getIsUserHasFullAccessSelector);
	const canI = usePermissions();
	const isUserPUMB = useAppSelector(getIsPumbUserAccessSelector);

	const userProfile = useAppSelector(getUserReducerSelector);

	const indexRoute = useMemo(() => {
		return getIndexRoute(canI);
	}, [canI, userProfile]);

	return (
		<MainLayout>
			<Switch>
				<Route exact path="/">
					<Redirect to={indexRoute} />
				</Route>
				{isUserPUMB && <Route exact path={ROUTER_URL.PROFILE} component={HomePage} />}
				<Route exact path={ROUTER_URL.PROFILE_RECEIPT} component={ReceiptPage} />
				<Route exact path={ROUTER_URL.SINGLE_RECEIPT} component={SingleReceiptPage} />
				<Route exact path={ROUTER_URL.RECEIPT_PAYMENT_STATUS} component={PaymentStatusPage} />
				<Route exact path={ROUTER_URL.RECEIPT_ARCHIVE_LIST} component={ReceiptBillsListPage} />
				<Route exact path={ROUTER_URL.RECEIPT_ARCHIVE} component={ReceiptBillsReportPage} />
				<Route exact path={ROUTER_URL.ADD_ADDRESS} component={AddAddressPage} />
				<Route exact path={ROUTER_URL.SETTINGS} component={SettingsPage} />
				{/* {canI(Rights.METERS_READ) && <Route path={ROUTER_URL.METERS} component={MetersPage} />} */}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_CATEGORIES} component={ServicesCategoriesPage} />}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_ARCHIVE_LIST} component={ServicesBillsListPage} />}
				{isHasFullAccess && (
					<Route exact path={ROUTER_URL.SERVICES_ARCHIVE_REPORT} component={ServicesBillsReportPage} />
				)}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICES_BY_CATEGORY_ID} component={ServicesPage} />}
				{isHasFullAccess && <Route exact path={ROUTER_URL.SERVICE_PROVIDER} component={ServiceProviderPage} />}
				{isHasFullAccess && (
					<Route exact path={ROUTER_URL.SERVICE_PAYMENT_STATUS} component={PaymentStatusServicePage} />
				)}
				<Route path="*" component={NotFoundPage} />
			</Switch>
		</MainLayout>
	);
};
