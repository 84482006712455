import { FC, Fragment, SyntheticEvent, useEffect, useState } from 'react';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ProviderServicesGroupTitle } from 'components/shared';
import { Checkbox } from 'components/shared/Checkbox';
import { ProviderAccordion } from 'components/shared/ProviderAccordion';
import { ProviderPreview } from 'components/shared/ProviderPreview';
import { PROVIDER_TYPE } from 'utils/enums';
import {
	convertUAHToCoins,
	getGroupedServicesListByType,
	getProvideIconByType,
	getProviderPreviewData,
	getProviderServicePayment,
	getProviderTitle,
} from 'utils/helpers';
import { IProviderServiceResponse, ISingleReceiptPayProviderServiceRequest } from 'utils/types';
import { EditServiceAmountToPay } from './EditServiceAmountToPay';
import { checkIsDataInArray } from './helpers';
import styles from './index.module.scss';

interface PaymentProviderItemProps {
	serviceList: IProviderServiceResponse[];
	onChange: (serviceList: ISingleReceiptPayProviderServiceRequest[]) => void;
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onExpandChange?: (expanded: boolean) => void;
	onChangeFee?: (serviceId: string | number, amount: number, hostCardAmount: number) => void;
}

export const PaymentProviderItem: FC<PaymentProviderItemProps> = ({
	serviceList,
	onChange,
	selectedServiceList,
	onExpandChange,
	onChangeFee,
}) => {
	const receipt = useAppSelector(getBillReceiptSelector);

	const [providerServicesList, setProviderServicesList] = useState<IProviderServiceResponse[]>([]);
	const [isShowCheckbox, _setIsShowCheckbox] = useState(true);
	const [feeAmount, setFeeAmount] = useState<number | null>(convertUAHToCoins(serviceList[0]?.fee ?? 0) || null);

	useEffect(() => {
		if (serviceList.length) {
			const payload: IProviderServiceResponse[] = serviceList.map((item) => {
				const selectedService = selectedServiceList.find((selectedService) => {
					return item.id === selectedService.id;
				});
				let amountToPay = 0;
				if (selectedService) {
					amountToPay = +selectedService.incomeBalance;
				} else {
					amountToPay = item.amountToPay > 0 ? item.amountToPay : 0;
				}
				return {
					...item,
					amountToPay,
				};
			});
			setProviderServicesList(payload);

			// uncomment if need. commented for the same view for all providers type to show checkbox
			// if (serviceList[0].providerType === PROVIDER_TYPE.PORTMONE) {
			// 	setIsShowCheckbox(!!serviceList[0].tarifItems && !serviceList[0].tarifItems.error && );
			// }
		}
	}, [serviceList]);

	useEffect(() => {
		if (providerServicesList.length) {
			const selectedServicesPayload: ISingleReceiptPayProviderServiceRequest[] = providerServicesList.reduce(
				(acc, item) => {
					const isServiceInSelected = !!acc.find(
						(selectedItem) => selectedItem.id && item.id && selectedItem.id === item.id
					);
					if (!isServiceInSelected) return acc;
					return acc.map((selectedItem) => {
						return selectedItem.id === item.id ? getProviderServicePayment(item) : selectedItem;
					});
				},
				[...selectedServiceList]
			);
			onChange(selectedServicesPayload);
		}
	}, [providerServicesList]);


	const toggleSelectService = (value: IProviderServiceResponse): void => {
		let payload = [];
		if (checkIsDataInArray(value, selectedServiceList)) {
			payload = selectedServiceList.filter((item) => item.id !== value.id);
		} else {
			const itemInProviderServiceList = providerServicesList.find((item) => item.id === value.id);
			payload = [...selectedServiceList, getProviderServicePayment(itemInProviderServiceList ?? value)];
		}
		onChange(payload);
	};

	const handleToggleManyItem = (event: SyntheticEvent): void => {
		event.stopPropagation();

		const isSomeServiceInSelected = providerServicesList.some((item: IProviderServiceResponse) =>
			checkIsDataInArray(item, selectedServiceList)
		);
		let payload: ISingleReceiptPayProviderServiceRequest[] = [];
		if (isSomeServiceInSelected) {
			payload = selectedServiceList.filter(
				(selectedService) => !providerServicesList.find((providerService) => providerService.id === selectedService.id)
			);
		} else {
			const servicesListWithDuplicate: ISingleReceiptPayProviderServiceRequest[] = [
				...selectedServiceList,
				...providerServicesList.map(getProviderServicePayment),
			];
			const payloadServiceCodeList = servicesListWithDuplicate.map(
				(item) => (item.id as number) || (item.serviceId as string)
			);
			const payloadUniqueServiceCodeList = [...new Set(payloadServiceCodeList)];
			payload = payloadUniqueServiceCodeList.reduce((acc, item) => {
				const reqServiceData = servicesListWithDuplicate.find(
					(serviceItem) => item === serviceItem.id || item === serviceItem.serviceId
				);
				return reqServiceData ? [...acc, reqServiceData] : acc;
			}, [] as ISingleReceiptPayProviderServiceRequest[]);
		}

		onChange(payload);
	};

	const handleChangeService = (data: IProviderServiceResponse): void => {
		const payload = providerServicesList.map((item) => (item.id === data.id ? data : item));
		setProviderServicesList(payload);
	};

	const checkIsServiceChecked = (data: IProviderServiceResponse): boolean => {
		const selectedServiceData = selectedServiceList.find((selectedItem) => selectedItem.id === data.id);
		return !!selectedServiceData;
	};

	const handleChangeFee = (
		providerType: PROVIDER_TYPE,
		serviceId: string | number,
		amount: number,
		hostCardAmount: number
	) => {
		onChangeFee?.(serviceId, amount, hostCardAmount);
		if (providerType === PROVIDER_TYPE.PORTMONE) {
			setFeeAmount(amount);
		}
	};

	return (
		<ProviderAccordion
			title={
				<div className={styles.wrapper}>
					<ProviderPreview
						title={getProviderTitle(serviceList[0])}
						description={getProviderPreviewData(serviceList)}
						icon={getProvideIconByType(serviceList[0].providerType, serviceList[0])}
						code={serviceList[0].code}
						providerType={serviceList[0].providerType}
						data={serviceList[0]}
					/>
					{!serviceList[0].isArchived && isShowCheckbox && (
						<Checkbox
							disableRipple
							onClickCapture={handleToggleManyItem}
							id={`${serviceList[0].providerType}-${serviceList[0].singleReceiptId}`}
							checked={serviceList.some((item) => checkIsDataInArray(item, selectedServiceList))}
							disabled={receipt.isLoading || receipt.data?.isArchived}
						/>
					)}
				</div>
			}
			onChange={(_, expanded: boolean) => onExpandChange?.(expanded)}
		>
			{getGroupedServicesListByType(providerServicesList, serviceList[0].providerType).map((item, index) => (
				<Fragment key={`grouped-service-${serviceList[0].providerType}-${index}`}>
					{!!item.title && <ProviderServicesGroupTitle title={item.title} marginTop="var(--spacing-2)" />}
					{item.list.map((serviceItem) => (
						<EditServiceAmountToPay
							key={`edit-service-${serviceItem.id}`}
							data={serviceItem}
							incomeBalance={
								serviceList.find((service: IProviderServiceResponse) => service.id === serviceItem.id)?.amountToPay || 0
							}
							onSelect={toggleSelectService}
							onChange={handleChangeService}
							isChecked={checkIsServiceChecked(serviceItem)}
							feeAmount={feeAmount}
							onChangeFee={handleChangeFee}
						/>
					))}
				</Fragment>
			))}
		</ProviderAccordion>
	);
};
