import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { getApartmentList, getSingleReceipt } from 'store/bill/actions';
import { resetReceipt } from 'store/bill/reducer';
import { getApartmentsSelector, getBillReceiptSelector } from 'store/bill/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { showToast } from 'store/toastify/reducer';
import { getUserProfile } from 'store/user-service/actions';
import { getUserIsLoadingSelector, getUserProfileSelector } from 'store/user-service/selectors';
import { ReceiptPageHeader } from 'components/Receipt';
import { SingleReceiptProviderItem } from 'components/Receipt/SingleReceiptItem';
import { CustomButton, Loader, ProviderAccordionGroup } from 'components/shared';
import { FIREBASE_EVENT_ANALYTICS_BUTTON, FIREBASE_EVENT_ANALYTICS_PAGE } from 'utils/constants';
import { ERROR_CODE, PROVIDER_TYPE, ROUTER_URL } from 'utils/enums';
import {
	getApartmentTitle,
	getErrorMessage,
	getErrorResponse,
	getReceiptErrorMessage,
	getReceiptRequestBody,
	sortProvidersObjByType,
} from 'utils/helpers';
import { FirebaseAnalytics } from 'utils/services';
import {
	IApartmentResponse,
	IBillApartments,
	IBillReceipt,
	IProviderServiceResponse,
	IUserProfileResponse,
} from 'utils/types';
import styles from './index.module.scss';

export const SingleReceiptPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const apartments: IBillApartments = useAppSelector(getApartmentsSelector);
	const profile: IUserProfileResponse | null = useAppSelector(getUserProfileSelector);
	const isLoadingUser: boolean = useAppSelector(getUserIsLoadingSelector);
	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);

	const [isLoading, setIsLoading] = useState(true);
	const [address, setAddress] = useState('');

	useEffect(() => {
		return () => {
			dispatch(resetReceipt());
		};
	}, []);

	useEffect(() => {
		setIsLoading(receipt.isLoading || apartments.isLoading || isLoadingUser);
	}, [receipt.isLoading, apartments.isLoading, isLoadingUser]);

	useEffect(() => {
		if (profile?.userId) {
			getReceipt(profile?.userId);
		} else {
			handleGetUserProfile();
		}
	}, [profile]);


	const handleGetUserProfile = async () => {
		try {
			await dispatch(getUserProfile()).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		}
	};

	const getReceipt = async (userId: string): Promise<void> => {
		try {
			// setIsLoading(true);
			const apartmentResponse = await dispatch(getApartmentList(userId)).unwrap();
			const lastAddedApartment: IApartmentResponse | undefined =
			apartmentResponse.listApartment[apartmentResponse.listApartment.length - 1];
			if (lastAddedApartment) {
				setIsLoading(true);
				setAddress(getApartmentTitle(lastAddedApartment));
				// TODO: remove request duplicate after backend fix
				await dispatch(getSingleReceipt(getReceiptRequestBody(lastAddedApartment.apartmentAccountId, userId))).unwrap();
				await dispatch(getSingleReceipt(getReceiptRequestBody(lastAddedApartment.apartmentAccountId, userId))).unwrap();
			} else {
				history.push(ROUTER_URL.NOT_FOUND);
			}
		} catch (error) {
			const errorResponse = getErrorResponse(error);
			dispatch(showToast({ message: getReceiptErrorMessage(error) }));
			if (errorResponse?.errorData?.code === ERROR_CODE.RECEIPT_NOT_FOUND) {
				history.push(ROUTER_URL.PROFILE);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleGoBack = () => {
		FirebaseAnalytics.logEvent(
			FIREBASE_EVENT_ANALYTICS_PAGE.PROFILE.SINGLE_RECEIPT,
			FIREBASE_EVENT_ANALYTICS_BUTTON.GO_BACK
		);
		history.push(ROUTER_URL.PROFILE);
	};

	const providerServices = sortProvidersObjByType(receipt.data?.serviceProviderData ?? {}) ?? [];

	return (
		<div className={classNames('page', styles.wrapper)}>
			{isLoading ? (
				<Loader />
			) : (
				receipt.data && (
					<div className="receipt-page-content">
						<ReceiptPageHeader className={styles.header} onBackClick={handleGoBack} />
						<Typography variant="h5" marginBottom="var(--spacing-6)" textAlign="center">
							{address}
						</Typography>
						{!!providerServices.length && (
							<Typography variant="subtitle1" marginBottom="var(--spacing-3)" textAlign="center">
								Ваша єдина квитанція
							</Typography>
						)}
						<div className={styles.list}>
							<ProviderAccordionGroup>
								{providerServices.map((item: IProviderServiceResponse[]) => (
									<SingleReceiptProviderItem key={item[0].providerType} serviceList={item} />
								))}
							</ProviderAccordionGroup>
							{(!!receipt.data.serviceProviderData[PROVIDER_TYPE.PORTMONE]?.length ||
								!!receipt.data.otherPaymentServices.length) && (
								<Typography variant="subtitle1" marginTop="var(--spacing-3)" textAlign="center">
									Ми знайшли наступні сервіси за адресою:
								</Typography>
							)}
							{!!receipt.data.serviceProviderData[PROVIDER_TYPE.PORTMONE]?.length && (
								<ProviderAccordionGroup marginBottom="var(--spacing-3)">
									{receipt.data.serviceProviderData[PROVIDER_TYPE.PORTMONE].map((item, index) => (
										<SingleReceiptProviderItem key={`${item.id ?? item.code}-${index}`} serviceList={[item]} />
									))}
								</ProviderAccordionGroup>
							)}
							{!!receipt.data.otherPaymentServices.length && (
								<ProviderAccordionGroup marginBottom="var(--spacing-3)">
									{receipt.data.otherPaymentServices.map((item, index) => (
										<SingleReceiptProviderItem
											key={`${item.id ?? item.code}-${index}`}
											serviceList={[item]}
											isOtherService
										/>
									))}
								</ProviderAccordionGroup>
							)}
							<Typography variant="subtitle1" textAlign="center">
								Ви можете самостійно додати інші сервіси всередині цієї адреси
							</Typography>
						</div>
						<CustomButton
							label="Продовжити"
							onClick={() => history.push(ROUTER_URL.PROFILE)}
							className={styles.button}
							size="large"
						/>
					</div>
				)
			)}
		</div>
	);
};
