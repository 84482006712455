import { FC, useEffect, useState } from 'react';
import { getBillReceiptSelector } from 'store/bill/selectors';
import { useAppSelector } from 'store/hooks';
import { ProviderAccordionGroup } from 'components/shared';
import { PROVIDER_TYPE } from 'utils/enums';
import { IBillReceipt, IProviderServiceResponse, ISingleReceiptPayProviderServiceRequest } from 'utils/types';
import { PaymentProviderItem } from '../PaymentProviderItem';

interface ReceiptPortmoneDefaultServiceListProps {
	selectedServiceList: ISingleReceiptPayProviderServiceRequest[];
	onChangeSelectedList: (value: ISingleReceiptPayProviderServiceRequest[]) => void;
	onChangeFee: (serviceId: string | number, amount: number, feeHostCardAmount: number) => void;
}

export const ReceiptPortmoneDefaultServiceList: FC<ReceiptPortmoneDefaultServiceListProps> = ({
	selectedServiceList,
	onChangeSelectedList,
	onChangeFee,
}) => {
	const receipt: IBillReceipt = useAppSelector(getBillReceiptSelector);

	const [serviceList, setServiceList] = useState<IProviderServiceResponse[][]>([]);

	useEffect(() => {
		const portmoneList = receipt.data?.serviceProviderData[PROVIDER_TYPE.PORTMONE];
		const payload = portmoneList?.length
			? portmoneList.filter((item) => item.providerAccountId).map((item) => [item])
			: [];
		setServiceList(payload);
	}, [receipt.data?.id]);

	if (!receipt.data) return null;
	return (
		<ProviderAccordionGroup marginBottom="var(--spacing-3)">
			{serviceList.map((portmoneService: IProviderServiceResponse[], index: number) => (
				<PaymentProviderItem
					key={`${portmoneService[0].id ?? portmoneService[0].code}-${index}`}
					serviceList={portmoneService}
					onChange={onChangeSelectedList}
					selectedServiceList={selectedServiceList}
					onChangeFee={onChangeFee}
				/>
			))}
		</ProviderAccordionGroup>
	);
};
