import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { getAmountTitle } from 'utils/helpers';
import styles from './index.module.scss';

type PaymentMethodFeeWrapperProps = {
	fee: number;
	className?: string;
};

export const PaymentMethodFeeWrapper: FC<PropsWithChildren<PaymentMethodFeeWrapperProps>> = ({
	fee,
	className = '',
	children,
}) => {
	return (
		<Box className={classNames(styles.wrapper, className)}>
			{children}
			<Typography variant="caption" textAlign="center">
				Комісія {getAmountTitle(fee)}
			</Typography>
		</Box>
	);
};
