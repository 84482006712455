import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Typography } from '@mui/material';
import {
	getAppLoadingSelector,
	getBillsReport,
	getBillsReportSelector,
	getReportPaymentReceipt,
	getReportPaymentReceiptSelector,
	getUserProfileSelector,
	openDocumentViewer,
	resetBillsReport,
	resetReportReceipt,
	setAppLoading,
	showToast,
	useAppDispatch,
	useAppSelector,
} from 'store';
import { BillsPaymentDetails, BillsReportProviderItem } from 'components/Bills';
import { ReceiptPageHeader } from 'components/Receipt';
import { Loader, ProviderAccordionGroup } from 'components/shared';
import { DATE_FORMAT, PAYMENT_STATUS, PDF_TYPE, PROVIDER_TYPE, ROUTER_URL } from 'utils/enums';
import {
	decryptData,
	formatUTCDateView,
	getErrorMessage,
	getPortmoneSubBillsByService,
	getReceiptBillsListRoute,
	getReportDownloadFileName,
	sortReportProvidersByType,
} from 'utils/helpers';
import { ReportService } from 'utils/services';
import { IBillsSubBillItem, IReportPaymentReceiptRequest, IReportPaymentReceiptResponse } from 'utils/types';
import styles from './index.module.scss';

export const ReceiptBillsReportPage: FC = () => {
	const history = useHistory();

	const dispatch = useAppDispatch();

	const params = useParams<{ apartmentAccountId: string; id: string }>();

	const billsReport = useAppSelector(getBillsReportSelector);
	const reportReceipt = useAppSelector(getReportPaymentReceiptSelector);
	const profile = useAppSelector(getUserProfileSelector);
	const appLoading = useAppSelector(getAppLoadingSelector);

	const [isOnlyOtherPayments, setIsOnlyOtherPayments] = useState(false);
	const [otherPaymentsSubBills, setOtherPaymentsSubBills] = useState<IBillsSubBillItem[][]>([]);

	useEffect(() => {
		if (!decryptData(params.apartmentAccountId)) {
			dispatch(showToast({ message: 'Помешкання не знайдено' }));
			history.replace(ROUTER_URL.NOT_FOUND);
			return;
		}

		return () => {
			dispatch(resetReportReceipt());
			dispatch(resetBillsReport());
		};
	}, []);

	useEffect(() => {
		if (profile && params.id) {
			handleGetBillsReport(params.id);
		}
	}, [profile]);

	useEffect(() => {
		if (billsReport.data) {
			const otherPaymentsSubBillsPayload = getPortmoneSubBillsByService(billsReport.data.subBills);
			setIsOnlyOtherPayments(
				[...billsReport.data.relatedBills, ...billsReport.data.subBills].every(
					(item) => item.provider === PROVIDER_TYPE.PORTMONE || item.provider === PROVIDER_TYPE.HOSTPAY
				)
			);
			setOtherPaymentsSubBills(otherPaymentsSubBillsPayload);
		}
	}, [billsReport.data]);

	useEffect(() => {
		if (billsReport.data && profile) {
			handleGetPaymentReceipt({ userId: profile.userId, id: billsReport.data.id });
		}
	}, [billsReport.data, profile]);

	const handleGetBillsReport = async (billId: string): Promise<void> => {
		try {
			await dispatch(getBillsReport(billId)).unwrap();
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
			history.push(ROUTER_URL.ERROR);
		}
	};

	const handleGetPaymentReceipt = async (
		reqBody: IReportPaymentReceiptRequest
	): Promise<IReportPaymentReceiptResponse | undefined> => {
		try {
			const data = await dispatch(getReportPaymentReceipt(reqBody)).unwrap();
			return data;
		} catch (error) {
			if (error?.statusCode !== 400 && error?.error !== 'BadRequest') {
				dispatch(showToast({ message: getErrorMessage(error) }));
			}
		}
	};

	const downloadReceiptPdf = async (): Promise<void> => {
		try {
			if (profile && billsReport.data) {
				dispatch(setAppLoading(true));
				let reciept: IReportPaymentReceiptResponse | null = reportReceipt.data;

				if (!reciept?.s3URL) {
					reciept = (await handleGetPaymentReceipt({ userId: profile.userId, id: billsReport.data.id })) ?? null;
				}

				if (!reciept?.s3URL) {
					throw new Error('Квитанцію ще не сформовано. Спробуйте через хвилину');
				}

				dispatch(
					openDocumentViewer({
						url: reciept.s3URL,
						isShareEnabled: true,
						fileName: getReportDownloadFileName(reciept, PDF_TYPE.PAYMENT),
					})
				);
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const handleDownloadOtherReceiptPdf = async (data: IBillsSubBillItem): Promise<void> => {
		try {
			if (profile && data.parentBillId) {
				dispatch(setAppLoading(true));
				const response = await ReportService.getPaymentReceipt({
					id: data.parentBillId,
					userId: profile.userId,
				});

				if (!response.data.s3URL) throw new Error('Квитанцію ще не сформовано. Спробуйте через хвилину');
				else if (response.data) {
					dispatch(
						openDocumentViewer({
							url: response.data.s3URL,
							isShareEnabled: true,
							fileName: getReportDownloadFileName(response.data, PDF_TYPE.PAYMENT),
						})
					);
				}
			}
		} catch (error) {
			dispatch(showToast({ message: getErrorMessage(error) }));
		} finally {
			dispatch(setAppLoading(false));
		}
	};

	const checkIsPaymentsWithOtherServiceProvider = (): boolean => {
		if (billsReport.data) {
			return !![...billsReport.data.subBills, ...billsReport.data.relatedBills].find(
				(item) => item.provider === PROVIDER_TYPE.HOSTPAY || item.provider === PROVIDER_TYPE.PORTMONE
			);
		}
		return false;
	};

	return (
		<div className="page">
			<div className="receipt-page-content">
				{!!billsReport.data && (
					<ReceiptPageHeader
						title={
							<>
								{!!billsReport.data.payerData.apartmentAccountId && (
									<Typography variant="subtitle1" component="span">
										{`HOST ID - ${billsReport.data.payerData.apartmentAccountId}`}
									</Typography>
								)}
								<Typography variant="subtitle1" component="span">
									{`Платіж № ${billsReport.data.shortenedId}`}
								</Typography>
							</>
						}
						description={
							<Typography className={styles.header__description} variant="body2" component="span">
								{formatUTCDateView(
									billsReport.data.paidAt ?? billsReport.data.createdAt,
									DATE_FORMAT.RADABANK_TOKEN_DATE
								)}
							</Typography>
						}
						onBackClick={() => history.push(getReceiptBillsListRoute(params.apartmentAccountId))}
						onDownload={!isOnlyOtherPayments && reportReceipt.data ? downloadReceiptPdf : undefined}
					/>
				)}
				{!!billsReport.data && (
					<div className="report-bills-content">
						<ProviderAccordionGroup>
							{sortReportProvidersByType(billsReport.data.subBills).map((item: IBillsSubBillItem[]) => (
								<BillsReportProviderItem key={item[0].id} serviceList={item} />
							))}
						</ProviderAccordionGroup>
						{checkIsPaymentsWithOtherServiceProvider() && (
							<ProviderAccordionGroup>
								<Typography variant="h6">Інші платежі</Typography>
								{otherPaymentsSubBills.map((item, index) => (
									<BillsReportProviderItem
										key={`${item[0].id}-${index}`}
										id={item[0].id}
										serviceList={item}
										// eslint-disable-next-line max-len
										onClickDownload={
											item.some((subBillItem) => subBillItem.status === PAYMENT_STATUS.PAID)
												? handleDownloadOtherReceiptPdf
												: undefined
										}
									/>
								))}

								{billsReport.data.relatedBills.map((relatedBill) => (
									<BillsReportProviderItem
										key={relatedBill.id}
										id={relatedBill.id}
										serviceList={relatedBill.subBills}
										onClickDownload={
											relatedBill.status === PAYMENT_STATUS.PAID ||
											(relatedBill.subBills.length &&
												relatedBill.subBills.some((item) => item.status === PAYMENT_STATUS.PAID))
												? handleDownloadOtherReceiptPdf
												: undefined
										}
									/>
								))}
							</ProviderAccordionGroup>
						)}
						<BillsPaymentDetails data={billsReport.data} />
					</div>
				)}
			</div>
			{(billsReport.isLoading || reportReceipt.isLoading || appLoading) && <Loader />}
		</div>
	);
};
